import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Summoner from './Summoner';
import Home from './Home';
import './App.css';

function App() {
  return (
    <Router>
      <div>

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/summoner/:name?" component={Summoner}/>
          <Route exact path="/" component={Home}/>
          <Route>
            <div>
              No Match
            </div>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}



export default App;
