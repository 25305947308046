import React from 'react';

function RowSummoners({rowSummoners}) {
    return (
      <div className="RowSummoners">
          {rowSummoners.map((rowSummoner) => (
                <div>
                    {rowSummoner}
                </div>
          ))}
      </div>
    );
  }

  
  
  export default RowSummoners;