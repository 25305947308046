import jinxImg from './images/champions/jinx.png';
import zoeImg from './images/champions/zoe.png';

let cost4 = 'cost4';
let cost1 = 'cost1';
let cost2 = 'cost2';
let cost3 = 'cost3';
let cost5 = 'cost5';

export const jinx = {
    image: jinxImg,
    cost: cost4
};

export const zoe = {
    image: zoeImg,
    cost: cost1
};
