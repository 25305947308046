import React from 'react';

import blademaster from './images/traits/blademaster.png';
import blaster from './images/traits/blaster.png';
import cybernetic from './images/traits/cybernetic.png';
import sniper from './images/traits/sniper.png';
import voidT from './images/traits/void.png';

function Traits({traits}) {
    return (
      <div className="Trait-Wrap">
          {traits.map((trait) => (
                <div>
                    <img src={getImage(trait)} className="Trait" alt={trait} />
                </div>
          ))}
      </div>
    );
  }

  function getImage(trait){
    switch(trait) {
      case "blademaster":
        return blademaster;
      case "cybernetic":
        return cybernetic;
      case "sniper":
        return sniper;
        case "blaster":
          return blaster;
      default:
        return voidT;
    }
  }

  
  
  export default Traits;