import React from 'react';

import {jinx, zoe} from './Unit.js';

function Units({units}) {
    return (
      <div className="Unit-Wrap">
          {units.map((unit) => (
                <div>
                    <div className={getImage(unit).cost}>
                      <img src={getImage(unit).image} className="Unit-Img" alt={unit} />
                    </div>
                </div>
          ))}
      </div>
    );
  }

  function getImage(unit){
    switch(unit) {
      case "jinx":
        return jinx;
      default:
        return zoe;
    }
  }

  
  
  export default Units;