import React from 'react';
import './App.css';
import Match from './Match';
import Header from './Header';
import {withRouter} from 'react-router';

function createData(summoner, traits, units, summoners) {
    return { summoner, traits, units, summoners };
  }

const rows = [
    createData('Jamoss96', ["battlecast", "blaster"], ["jinx", "illaoi"], ["Bob", "Jeff"]),
    createData('Bob', ["cybernetic", "sniper"], ["vayne", "leona"], ["Bob", "Jamoss96"])
  ];

class Summoner extends React.Component{

    constructor(props) {
        super(props);
        this.state = {};
      }

    loadSummoner(id){
        console.log(id);
    }

    componentWillMount() {
        this.loadSummoner(this.props.match.params.name);
        
      }
    

render(){
  return (
    <div className="App">
      <Header/>
      <div className="body">
        <Match matches={rows}></Match>
      </div>
      <header className="App-header"></header>
    </div>
  );
}


  
  

    
}

export default withRouter(Summoner);
