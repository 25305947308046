import React from 'react';
import Header from './Header';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField'
import {
    useHistory
  } from "react-router-dom";

  

//const Url = 'localhost:8080/';

class Home extends React.Component{
  

    constructor(props) {
        super(props);
        this.state = {summonerName: ""};
      }

    render(){
        return (
            <div className="App">
              <Header/>
              <div className="body">
                <p>
                  Please enter your summoner name.
                </p>
                <div className="SummonerInput">
                <TextField id="filled-basic" class= "SummonerInput-TextField" value={this.state.summonerName} onChange={this.handleSummonerChange.bind(this)}></TextField> 
                <Button variant="contained" onClick={() => this.handleOnClick()}>Submit </Button> 
                </div>
                </div>
                <header className="App-header"></header>
                
            </div>
          );
    }

    handleOnClick() {
        this.props.history.push("/summoner/" + this.state.summonerName)
      }

      handleSummonerChange(event) {
        this.setState({summonerName: event.target.value})
      }
}


export default Home;
