import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Units from './Units';
import Traits from './Traits';
import RowSummoners from './RowSummoners';

function Match(matches) {
    return (
      <div className="SummonerTable">
          <TableContainer component={Paper}>
              <Table className="table" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Summoner</TableCell>
                    <TableCell >Traits</TableCell>
                    <TableCell >Units</TableCell>
                    <TableCell >Summoners</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {matches.matches.map((row) => (
                    <TableRow key={row.summoner}>
                      <TableCell align="left" component="th" scope="row">
                        {row.summoner}
                      </TableCell>
                      <TableCell ><Traits traits={row.traits}/></TableCell>
                      <TableCell ><Units units={row.units}/></TableCell>
                      <TableCell ><RowSummoners rowSummoners={row.summoners}/></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
      </div>
    );
  }


  
  
  export default Match;