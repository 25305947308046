import React from 'react';
import logo from './tft2.png';

function Header(){
    return (
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h1>TFT Analytics</h1>
        </header>
    );
}

export default Header;
